import React from 'react'
import { Link} from 'react-router-dom';
import { Grid, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
const NewsItems =(props) => {
  let {title,id, description,category, imageUrl,newsUrl,auther,updateDate,source,urlToImage} = props;
  const isYouTubeLink = (url) => {
      const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|shorts)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      return youtubeRegex.test(url);
  };
  const getYouTubeVideoId = (url) => {
      const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|shorts)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
      return match ? match[1] : null;
  };
  const handleShare = async () => {
    try {
        if (navigator.share) {
            await navigator.share({
                title: title,
                url: `/news?title=${title}&category=${category}&news=${btoa(id)}`
            });
            console.log('Content shared successfully.');
        } else {
            console.log('Web Share API not supported.');
        }
    } catch (error) {
        console.error('Error sharing content:', error);
    }
};
  return (
    <div key={`news_item_${id}`} >
            <div key={`news_item1_${id}`} className="card" >
            <a href={`/news?title=${title}&news=${btoa(id)}&category=${category}`}>
            {isYouTubeLink(imageUrl) ? (
                <iframe
                    key={`news_item2_${id}`}
                    width="100%"
                    height="200"
                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(imageUrl)}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video"
                ></iframe>
            ) : (
                <img key={`news_item2_${id}`} src={imageUrl} className="card-img-top" alt="/" />
            )}
            </a>
            <div className="card-body" key={`news_item3_${id}`} >
                <h5 className="card-title" key={`news_item4_${id}`} >
                <a className="nav-link"
                    href={`/news?title=${title}&news=${btoa(id)}&category=${category}`}
                    >{title}
                </a>
                <span key={`news_item5_${id}`}  className='position-absolute top-0 translate-middle badge bg-danger rounded-pill' style={{left:'90%', zIndex:'1'}}>{source}</span></h5>
                <p key={`news_item6_${id}`}  className="card-text">{description}</p>
                <p key={`news_item7_${id}`}  className="card-text"><small className='text-muted'>By {auther} on {updateDate}</small></p>
                <a target='_blank'
                    className="btn btn-sm btn-primary"
                    href={`/news?title=${title}&news=${btoa(id)}&category=${category}`}
                    >Read More
                </a>
                <Button
                onClick={handleShare} // Change to Button from MUI
                variant="contained"
                color="secondary"
                size="small"
                style={{ marginLeft: '8px' }} // Adjust spacing
              >
                Share
              </Button>
            </div>
            </div>
      </div>
    )
}
export default NewsItems