import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {BrowserRouter as Router,Routes,
    Route, Link
} from 'react-router-dom'

const NavBar = () => {
    return ( <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
              <Link className="navbar-brand" to='/'>G1News</Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                          <Link className="nav-link active" aria-current="page" to='/'>Home</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/business'>Business</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/entertainment'>Entertainment</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/general'>General</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/health'>Health</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/science'>Science</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/sport'>Sport</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/technology'>Technology</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/story'>Story</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/jokes'>Jokes</Link>
                      </li>
                      <li className="nav-item">
                          <Link className="nav-link" to='/shayari'>Shayari</Link>
                      </li>
                  </ul>
              </div>
          </div>
      </nav>
      </>
  );
}

export default NavBar
